.DonationThankYou .donationThankYou {
  padding: 0 0;
  text-align: center;
  height: 100%;
}

.DonationThankYou .donationThankYou div {
  padding-top: 5px;
}

.DonationThankYou .donationThankYou h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.DonationThankYou .donationThankYou p {
  color: #000;
  font-size: 10pt;
}

.DonationThankYou .row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.DonationThankYou .rowOne {
  background-color: white;
  min-height: 625px;
  text-align: justify;
  margin-top: 20px;
}