.FindMedicationResults .findMedicationResults {
  padding: 0 0;
  text-align: center;
  height: 100%;
}

.FindMedicationResults .findMedicationResults div {
  padding-top: 5px;
}

.FindMedicationResults .findMedicationResults h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.FindMedicationResults .findMedicationResults p {
  color: #000;
  font-size: 10pt;
}

.FindMedicationResults .row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.FindMedicationResults .rowOne {
  background-color: white;
  min-height: 210px;
  text-align: justify;
}

.FindMedicationResults .formSpace {
  padding-left: 20px;
}

.FindMedicationResults .rowTwo {
  background-color: white;
  min-height: 590px;
  text-align: justify;
}