/* Brand colors */
/* Supporting colors */
.DonationFormMedicationInfo .donationHeader {
  background-color: #164f84;
  height: 30px;
  width: 100%;
  color: white;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 20px; }
  .DonationFormMedicationInfo .donationHeader--close {
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    float: right;
    background-image: url("/assets/close-ico-circle.svg"); }

.DonationFormMedicationInfo .row--three {
  margin-top: 20px;
  text-align: center; }

.dtPicker {
  cursor: pointer; }
