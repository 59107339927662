.MedicationDetail .medicationDetail {
  padding: 0 0;
  text-align: center;
  height: 100%;
}

.MedicationDetail .medicationDetail div {
  padding-top: 5px;
}

.MedicationDetail .medicationDetail h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.MedicationDetail .medicationDetail p {
  color: #000;
}