/* Brand colors */
/* Supporting colors */
.Home .home {
  padding: 0 0;
  height: 100%; }
  .Home .home .row {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%; }
    .Home .home .row .btn-toolbar {
      margin-top: 60px; }
    .Home .home .row--one {
      background-image: url("/assets/mom-daughter.jpg");
      height: 600px;
      min-height: 600px;
      display: flex;
      align-items: center;
      color: #000; }
      .Home .home .row--one .btn-toolbar .second .btn {
        margin-left: 24px; }
      .Home .home .row--one h2 {
        font-size: 40px;
        font-weight: bold; }
      .Home .home .row--one p {
        font-size: 20px;
        line-height: 1.25; }
      @media (max-width: 1000px) {
        .Home .home .row--one {
          background-image: none; } }
    .Home .home .row--two {
      background-color: white;
      margin-top: 50px;
      min-height: 190px; }
      .Home .home .row--two h3 {
        font-size: 30px; }
    .Home .home .row--three {
      background-color: #FBF8E9;
      color: #000; }
    .Home .home .row--four {
      padding-top: 30px;
      background-color: #FBF8E9;
      background-image: url("/assets/kite-bg.jpg");
      min-height: 1270px;
      color: #000; }
      .Home .home .row--four h2 {
        font-size: 40px; }
      .Home .home .row--four p {
        font-size: 20px;
        line-height: 1.25; }
      @media (max-width: 1000px) {
        .Home .home .row--four {
          background-image: none; } }

.Home .learn-more {
  padding: 120px 80px 0;
  display: flex; }
  @media (max-width: 1000px) {
    .Home .learn-more {
      background-image: none;
      padding: 0;
      width: 325px; } }
  .Home .learn-more a {
    text-decoration: none; }
    .Home .learn-more a .btn {
      width: 100%;
      display: block;
      margin-top: 20px; }
      .Home .learn-more a .btn:hover, .Home .learn-more a .btn:focus {
        box-shadow: inset 2px 3px 6px rgba(0, 0, 0, 0.125); }
  .Home .learn-more .card {
    color: #fff;
    text-align: center;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .Home .learn-more .card span {
      font-size: 15px; }
  .Home .learn-more .whyDonate .card {
    background-color: #164f84; }
    .Home .learn-more .whyDonate .card .btn {
      background-color: #003065;
      border-color: #003065; }
  .Home .learn-more .whatDonate .card {
    background-color: #FBA740; }
    .Home .learn-more .whatDonate .card .btn {
      background-color: #AE742E;
      border-color: #AE742E; }
  .Home .learn-more .pharmacies .card {
    background-color: #BA6B4E; }
    .Home .learn-more .pharmacies .card .btn {
      background-color: #824C38;
      border-color: #824C38; }
  .Home .learn-more .environment .card {
    background-color: #81B548; }
    .Home .learn-more .environment .card .btn {
      background-color: #4e9d38;
      border-color: #4e9d38; }
