/* Brand colors */
/* Supporting colors */
.warning-bar {
  width: 100%;
  background-color: #81B548; }
  .warning-bar p {
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white; }
  .warning-bar img {
    float: left;
    margin-top: 10px;
    padding-right: 10px; }
  .warning-bar .close {
    position: absolute;
    top: 10px;
    right: 20px;
    width: 20px;
    height: 20px;
    vertical-align: middle; }

.navbar {
  height: 88px;
  margin-bottom: 0; }
  .navbar li {
    list-style: none; }
  .navbar.navbar-default {
    background-color: #fff;
    background-image: none;
    background-repeat: unset;
    border: none;
    border-radius: 0;
    box-shadow: none; }
    .navbar.navbar-default .container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      width: 100%;
      border-bottom: 1.5px solid #ccc; }
    .navbar.navbar-default .navbar-nav li {
      margin: auto; }
      .navbar.navbar-default .navbar-nav li.btn {
        height: 50px;
        background-color: #164f84; }
        .navbar.navbar-default .navbar-nav li.btn a {
          padding: 6px 12px;
          color: #fff; }
    .navbar.navbar-default .navbar-nav .header-loggin {
      background-color: #81B548; }
    .navbar.navbar-default .navbar-collapse {
      height: 50px !important; }
  .navbar .right {
    font-weight: bold; }
    .navbar .right > li:first-child > a {
      padding: 0;
      margin: 8px 15px; }
  .navbar .dropdown {
    height: 50px !important; }
    .navbar .dropdown button {
      background-color: #fff;
      color: #777;
      border: none;
      font-size: 16px;
      padding: 14px 12px; }
      .navbar .dropdown button:active {
        background-color: #fff; }
    .navbar .dropdown .dropdown-menu {
      background-color: #164f84;
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
      left: calc(100% - 34px) !important;
      margin-top: 12px;
      padding: 0;
      text-shadow: none;
      width: fit-content; }
      .navbar .dropdown .dropdown-menu button:hover, .navbar .dropdown .dropdown-menu button:focus {
        outline: none; }
      .navbar .dropdown .dropdown-menu li:not(:last-child) {
        border-bottom: 1px solid #003065; }
      .navbar .dropdown .dropdown-menu > li > a {
        color: #fff;
        padding: 14px 12px;
        transition: all 0.125s ease; }
        .navbar .dropdown .dropdown-menu > li > a:hover, .navbar .dropdown .dropdown-menu > li > a:focus {
          background-color: #003065;
          background-image: none;
          background-repeat: unset;
          color: #fff; }
      .navbar .dropdown .dropdown-menu > li:first-child a:hover,
      .navbar .dropdown .dropdown-menu > li:first-child a:focus {
        border-radius: 4px 4px 0 0; }
      .navbar .dropdown .dropdown-menu > li:last-child a:hover,
      .navbar .dropdown .dropdown-menu > li:last-child a:focus {
        border-radius: 0 0 4px 4px; }
      .navbar .dropdown .dropdown-menu::before {
        content: '';
        height: 0;
        width: 0;
        border-top: 10px solid transparent;
        border-right: 10px solid transparent;
        border-left: 10px solid transparent;
        border-bottom: 10px solid #164f84;
        position: absolute;
        top: -20px;
        left: 10px; }
  .navbar .navbar-brand {
    list-style: none;
    margin-left: 0 !important; }
  @media (max-width: 860px) {
    .navbar .mainHeader {
      display: none !important; } }

.navbar-default .navbar-nav .btn-success:hover {
  background-color: #4e9d38;
  border-color: #4e9d38; }

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 30px;
  right: 25px;
  top: 25px;
  display: none; }
  @media (max-width: 860px) {
    .bm-burger-button {
      display: block; } }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47; }

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7; }

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%; }

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em; }
  .bm-menu .bm-item-list {
    list-style: none; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47; }

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em; }

/* Individual item */
.bm-item {
  display: inline-block; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }
