/* Brand colors */
/* Supporting colors */
.DonateHow .donateHow {
  padding: 0 0;
  height: 100%; }
  .DonateHow .donateHow p {
    font-size: 11pt;
    text-align: justify; }
  .DonateHow .donateHow .row {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    color: white; }
    .DonateHow .donateHow .row--one {
      background-image: url("/assets/father-daughter.jpg");
      min-height: 350px;
      padding-top: 15px !important; }
    .DonateHow .donateHow .row--two {
      background-color: #164f84;
      min-height: 485px;
      padding-top: 135px !important; }
      .DonateHow .donateHow .row--two--coltwo {
        padding-top: 104px !important; }
    .DonateHow .donateHow .row--three {
      background-color: #81B548;
      min-height: 485px;
      padding-top: 55px !important; }
      .DonateHow .donateHow .row--three .btn {
        color: #81B548;
        background-color: #fff;
        border-color: #fff; }
        .DonateHow .donateHow .row--three .btn:hover {
          color: #81B548;
          background-color: #f4f4f4;
          border-color: #ccc; }
      .DonateHow .donateHow .row--three--colone {
        padding-top: 184px !important; }
        .DonateHow .donateHow .row--three--colone img {
          float: right; }
    .DonateHow .donateHow .row--four {
      background-color: #164f84;
      min-height: 485px;
      padding-top: 55px !important; }
