.pharmacyPreferences .rowOne {
    background-color: white;
    min-height: 190px;
  }
  
  .pharmacyPreferences .rowTwo {
    background-color: white;
    min-height: 390px;
  }
  
  .pharmacyPreferences .formSpace {
    padding-left: 20px;
  }