/* Brand colors */
/* Supporting colors */
.footer {
  height: 90px;
  color: #999; }
  .footer .container {
    width: 100%;
    padding-top: 15px;
    border-top: 1.5px solid #ccc; }
  .footer--links-contain {
    display: flex;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px; }
  .footer--links span {
    display: block;
    padding-bottom: 4px; }
  .footer--links ul {
    list-style: none;
    display: inline-block;
    padding: 0; }
  .footer--links li {
    display: inline-block;
    color: red; }
    .footer--links li:not(:last-of-type)::after {
      content: '|';
      padding: 0 8px; }
