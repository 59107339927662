/* Brand colors */
/* Supporting colors */
.FindPharmacy .findPharmacy {
  padding: 0 0;
  text-align: center;
  height: 100%; }
  .FindPharmacy .findPharmacy .row {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%; }
    .FindPharmacy .findPharmacy .row button {
      margin-top: 24px; }
    .FindPharmacy .findPharmacy .row--one {
      background-color: white;
      padding-top: 30px;
      min-height: 180px;
      text-align: justify; }
      .FindPharmacy .findPharmacy .row--one:after {
        transition: all 1s ease-in-out;
        content: ""; }
      .FindPharmacy .findPharmacy .row--one.with-results:after {
        opacity: 0; }
      .FindPharmacy .findPharmacy .row--one.with-results .row-title {
        transition: all 1s ease-in-out; }
    .FindPharmacy .findPharmacy .row--two {
      transition: all 1s ease-in-out; }
      .FindPharmacy .findPharmacy .row--two .pharmaciesList--item {
        text-align: left;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #ccc; }
        .FindPharmacy .findPharmacy .row--two .pharmaciesList--item:last-of-type {
          border: none; }
      .FindPharmacy .findPharmacy .row--two .pharmaciesList ul {
        padding-left: 0; }
      .FindPharmacy .findPharmacy .row--two .pharmaciesList li {
        list-style: none; }
        .FindPharmacy .findPharmacy .row--two .pharmaciesList li a {
          color: #464646; }
    .FindPharmacy .findPharmacy .row .orSpace {
      padding-left: 50px;
      padding-right: 50px;
      vertical-align: -12px; }

.pharmaciesList {
  padding: 0; }
  .pharmaciesList--item {
    text-align: left;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #ccc; }
    .pharmaciesList--item:last-of-type {
      border: none; }
  .pharmaciesList ul {
    padding-left: 0; }
  .pharmaciesList li {
    list-style: none; }
