/* Brand colors */
/* Supporting colors */
.FindMedication .findMedication {
  padding: 0 0;
  height: 100%; }
  .FindMedication .findMedication .row {
    height: 100%; }
    .FindMedication .findMedication .row--one {
      color: #000;
      padding-top: 50px !important;
      min-height: 810px;
      text-align: justify;
      transition: all 1s ease-in-out;
      background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.65) 65%, white 100%), url("/assets/mother-holding-child.jpg") no-repeat;
      background-size: cover; }
      .FindMedication .findMedication .row--one.with-results {
        min-height: 300px; }
        .FindMedication .findMedication .row--one.with-results:after {
          opacity: 0; }
        .FindMedication .findMedication .row--one.with-results .row-title {
          transition: all 1s ease-in-out; }
    .FindMedication .findMedication .row--two .medicationList--item {
      padding-top: 10px;
      padding-bottom: 10px;
      border-bottom: 1px solid #ccc; }
      .FindMedication .findMedication .row--two .medicationList--item button {
        float: right; }
      .FindMedication .findMedication .row--two .medicationList--item:last-of-type {
        border: none; }
    .FindMedication .findMedication .row--two .medicationList ul {
      padding-left: 0; }
    .FindMedication .findMedication .row--two .medicationList li {
      list-style: none; }
  .FindMedication .findMedication h2 {
    font-size: 40px;
    font-weight: bold; }
  .FindMedication .findMedication .formSpace {
    padding-left: 20px; }
