/* Brand colors */
/* Supporting colors */
.Donate .donate {
  padding: 0 0;
  height: 100%; }
  .Donate .donate .row {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%; }
    .Donate .donate .row--one {
      color: #000;
      background: linear-gradient(rgba(255, 255, 255, 0.25), rgba(255, 255, 255, 0.25)), url("/assets/girl-bunny.jpg");
      background-size: cover;
      min-height: 785px; }
      .Donate .donate .row--one--one {
        padding-top: 125px !important; }
        .Donate .donate .row--one--one h2 {
          font-size: 40px;
          font-weight: bold;
          padding-bottom: 24px; }
        .Donate .donate .row--one--one p {
          font-size: 16px;
          line-height: 1.25; }
      .Donate .donate .row--one--two {
        padding: 95px 120px 0;
        text-align: center; }
        .Donate .donate .row--one--two .donation-col {
          max-height: 280px;
          min-height: 280px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px; }
          .Donate .donate .row--one--two .donation-col p {
            max-width: 300px; }
        .Donate .donate .row--one--two .circle-image-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          background-color: #fff;
          height: 115px;
          width: 115px;
          margin-bottom: 24px; }
    .Donate .donate .row--two {
      background: linear-gradient(rgba(0, 0, 0, 0.05), rgba(0, 0, 0, 0.05)), url("/assets/wheelchair-nurse.jpg");
      background-size: cover;
      min-height: 710px;
      margin-top: 0px;
      padding-top: 70px !important; }
      .Donate .donate .row--two--one .btn-toolbar {
        margin-left: 0; }
        .Donate .donate .row--two--one .btn-toolbar .btn-group {
          margin-left: 0;
          margin-bottom: 5px; }
          .Donate .donate .row--two--one .btn-toolbar .btn-group .btn {
            border-radius: 0;
            border-color: #fff;
            background-color: #fff;
            color: #164f84; }
            .Donate .donate .row--two--one .btn-toolbar .btn-group .btn.active {
              color: #fff;
              background-color: #164f84;
              border-color: #164f84; }
      .Donate .donate .row--two--one .donation-form {
        padding: 20px;
        background-color: #fff;
        flex-direction: column;
        justify-content: space-between;
        align-items: center; }
        .Donate .donate .row--two--one .donation-form input {
          margin-bottom: 16px;
          border-radius: 0; }
        .Donate .donate .row--two--one .donation-form button {
          margin: auto;
          display: flex;
          align-items: center; }
      .Donate .donate .row--two--two .page-title {
        margin-bottom: 20px; }
    .Donate .donate .row--three {
      background-color: white;
      min-height: 620px;
      padding-top: 70px !important; }
      .Donate .donate .row--three--one {
        text-align: center; }
        .Donate .donate .row--three--one .page-title {
          text-align: center;
          margin-bottom: 20px; }
      .Donate .donate .row--three--two {
        padding-top: 95px !important; }
        .Donate .donate .row--three--two ul,
        .Donate .donate .row--three--two ol,
        .Donate .donate .row--three--two dl {
          text-align: left; }
