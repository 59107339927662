@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
  }

  .Login p {
    font-size: 9pt;
  }

  .wrapper {
    position: relative;
    /* width: 50px; */
    height: 300px;
    margin: 0;
    padding: 0;
  }

  .line {
      position: absolute;
      left: 49%;
      top: 0;
      bottom: 0;
      width: 1px;
      background: #ccc;
      z-index: 1;
  }

  .wordwrapper {
      text-align: center;
      height: 12px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      margin-top: -12px;
      z-index: 2;
  }

  .word {
      color: #ccc;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding: 3px;
      font: bold 12px arial,sans-serif;
      background: #fff;
  }
}

