/* Brand colors */
/* Supporting colors */
.DonationForm .donationError {
  text-align: center; }

.DonationForm .radio--group {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px; }
  .DonationForm .radio--group--button input {
    margin-right: 10px; }

.DonationForm .form--inline {
  padding: 0; }
  .DonationForm .form--inline div {
    padding: 0; }
    .DonationForm .form--inline div:nth-of-type(1) {
      padding-right: 10px; }

.DonationForm .form--inline-btn {
  padding: 15px 0;
  border: 1px solid black; }

.DonationForm .row--one {
  padding-top: 50px; }

.DonationForm .row--two {
  padding-bottom: 40px; }

.DonationForm ol.progtrckr {
  list-style-type: none;
  padding: 0; }
  .DonationForm ol.progtrckr li {
    display: inline-block;
    text-align: center;
    line-height: 5.5rem;
    cursor: pointer; }
    .DonationForm ol.progtrckr li span {
      padding: 0 1.5rem; }
    .DonationForm ol.progtrckr li:before {
      background-color: #164f84;
      display: block;
      content: ' ';
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      height: 50px;
      width: 50px;
      border-radius: 25px;
      position: relative;
      bottom: -3.7rem;
      float: left;
      left: 50%; }
    .DonationForm ol.progtrckr li:nth-of-type(1).progtrckr-todo:before {
      background-image: url("/assets/person-ico.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(1):before {
      left: -10px;
      background-image: url("/assets/person-ico-white.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(2).progtrckr-todo:before {
      background-image: url("/assets/pill-ico.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(2):before {
      left: 50%;
      background-image: url("/assets/pill-ico-white.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(3).progtrckr-todo:before {
      background-image: url("/assets/correct-ico.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(3):before {
      left: 100%;
      background-image: url("/assets/correct-ico-white.svg"); }
    .DonationForm ol.progtrckr li:nth-of-type(4) {
      display: none; }
    .DonationForm ol.progtrckr li.progtrckr-todo {
      color: #777;
      border-bottom: 4px solid #a6d4ff; }
      .DonationForm ol.progtrckr li.progtrckr-todo:before {
        background-color: #a6d4ff; }
    .DonationForm ol.progtrckr li.progtrckr-doing, .DonationForm ol.progtrckr li.progtrckr-done {
      color: #164f84;
      border-bottom: 4px solid #164f84; }
      .DonationForm ol.progtrckr li.progtrckr-doing:before, .DonationForm ol.progtrckr li.progtrckr-done:before {
        background-color: #164f84; }
    .DonationForm ol.progtrckr li.progtrckr-doing:before, .DonationForm ol.progtrckr li.progtrckr-done:before, .DonationForm ol.progtrckr li.progtrckr-todo:before {
      display: block;
      content: ' ';
      background-size: 24px;
      background-repeat: no-repeat;
      background-position: center;
      height: 40px;
      width: 40px; }

@media (max-width: 650px) {
  .progtrckr li span {
    display: none; } }

.progtrckr em {
  display: none;
  font-weight: 700;
  padding-left: 1rem; }

@media (max-width: 650px) {
  .progtrckr em {
    display: inline; } }

ol.progtrckr li:after {
  content: "\00a0\00a0"; }

ol.progtrckr li:before {
  position: relative;
  bottom: -3.7rem;
  float: left;
  left: 50%; }

ol.progtrckr li.progtrckr-doing:hover:before {
  color: #ff4500; }

ol.progtrckr li.progtrckr-done:hover:before {
  color: #333; }

/* local page styles */
.progtrckr {
  text-align: center;
  padding-bottom: 16px; }

.progtrckr li {
  margin-bottom: 10px; }

.val-err-tooltip {
  background-color: red;
  padding: 3px 5px 3px 10px;
  font-size: 14px;
  color: #fff; }

html .form-horizontal .form-group {
  margin: 0; }

.footer-buttons {
  margin-top: 10px;
  width: 180px;
  margin: 0 auto; }

html .step3 label, html .step4 label {
  font-size: 20px;
  text-align: left; }

html .form-horizontal .control-label {
  text-align: left; }

.review .txt {
  font-size: 20px;
  text-align: left;
  margin: 0;
  padding: 0; }

html body .saving {
  background-color: #5cb85c;
  width: 90%;
  padding: 5px;
  font-size: 16px; }

code {
  position: relative;
  left: 12px;
  line-height: 25px; }

.eg-jump-lnk {
  margin-top: 50px;
  font-style: italic; }

.lib-version {
  font-size: 12px;
  background-color: rgba(255, 255, 0, 0.38);
  position: absolute;
  right: 10px;
  top: 10px;
  padding: 5px; }

html .content {
  margin-left: 10px; }

span.red {
  color: #d9534f; }

span.green {
  color: #3c763d; }

span.bold {
  font-weight: bold; }

html .hoc-alert {
  margin-top: 20px; }

html .form-block-holder {
  margin-top: 20px !important; }
