/* Brand colors */
/* Supporting colors */
.Environment .environment {
  padding: 0 0;
  height: 100%; }
  .Environment .environment p {
    font-size: 11pt;
    text-align: justify;
    padding-top: 15px; }
  .Environment .environment .row {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 100%;
    color: white; }
    .Environment .environment .row--one {
      background-image: url("/assets/environment.jpg");
      min-height: 750px;
      padding-top: 100px !important; }
      .Environment .environment .row--one .button-contain {
        padding-top: 30px; }
    .Environment .environment .row--two {
      background-color: #164f84;
      min-height: 300px;
      padding-top: 80px !important; }
      .Environment .environment .row--two .col-center {
        text-align: center; }
        .Environment .environment .row--two .col-center .button-contain {
          padding-top: 30px; }
      .Environment .environment .row--two .btn {
        color: #81B548;
        background-color: #fff;
        border-color: #fff; }
        .Environment .environment .row--two .btn:hover {
          color: #81B548;
          background-color: #f4f4f4;
          border-color: #ccc; }
