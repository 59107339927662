/* Brand colors */
/* Supporting colors */
.Terms .terms {
  padding: 0 0;
  text-align: left;
  height: 100%; }
  .Terms .terms div {
    padding-top: 5px; }
  .Terms .terms--title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: left;
    text-align: center; }
  .Terms .terms p {
    color: #000; }
  .Terms .terms--subhead {
    font-style: italic;
    text-align: center; }
  .Terms .terms--subTitle {
    font-weight: 600;
    font-size: 22px; }
  .Terms .terms--content {
    text-align: justify; }
