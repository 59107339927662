.donationsArchives .rowOne {
    background-color: white;
    min-height: 110px;
  }
  
  .donationsArchives .rowTwo {
    background-color: white;
    min-height: 510px;
  }
  
  .donationsArchives .formSpace {
    padding-left: 20px;
  }