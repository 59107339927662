/* Brand colors */
/* Supporting colors */
/* Brand colors */
/* Supporting colors */
:root {
  /* Brand colors */
  --green-cpharm: #6DBC57;
  --green-cpharm-dark: #4e9d38;
  --green-cpharm-darker: #2f7e19;
  --blue-dark-cpharm: #164f84;
  --blue-dark-cpharm-dark: #003065;
  --blue-dark-cpharm-darker: #001146;
  --blue-light-cpharm: #a6d4ff;
  /* Supporting colors */
  --black: #000;
  --gray-darker: #464646;
  --gray-dark: #666;
  --gray-bit-darker: #999;
  --gray: #ccc;
  --gray-light: #f4f4f4;
  --white: #fff;
  --brown: #BA6B4E;
  --brown-dark: #824C38;
  --brown-darker: #632d19;
  --green: #6DBC57;
  --green-dark: #4E833E;
  --red: #DB3949;
  --red-dark: #bc1a2a;
  --yellow: #FBA740;
  --yellow-dark: #AE742E;
  --yellow-darker: #8f550f; }

body {
  margin: 0;
  padding: 0;
  font-family: Cabin, "Open Sans", sans-serif;
  font-size: 16px;
  color: var(--gray-darker); }

select.form-control,
textarea.form-control,
input.form-control {
  font-size: 16px; }

.btn-currentLocation {
  background-color: #697276;
  border-color: #697276;
  color: white; }
  .btn-currentLocation:hover, .btn-currentLocation:focus, .btn-currentLocation:active {
    background-color: #5d6569;
    border-color: #51585b;
    color: white; }
  .btn-currentLocation.disabled:hover, .btn-currentLocation.disabled:focus, .btn-currentLocation.disabled:active, .btn-currentLocation:disabled:hover, .btn-currentLocation:disabled:focus, .btn-currentLocation:disabled:active {
    background-color: #697276;
    border-color: #697276;
    color: white; }

fieldset:disabled .btn-currentLocation:hover, fieldset:disabled .btn-currentLocation:focus, fieldset:disabled .btn-currentLocation:active {
  background-color: #697276;
  border-color: #697276;
  color: white; }

.card {
  border-radius: 4px; }
  .card img {
    width: 100%;
    border-radius: 4px 4px 0 0; }

/* .card img {
  width: 100%;
  border-radius: 4px 4px 0 0; } */
.card-body {
  padding: 24px 12px 20px; }

.d-block {
  display: block !important; }

.d-inline-block {
  display: inline-block !important; }

.d-inline {
  display: inline !important; }

/* Bootstrap overrides */
.btn {
  /* All .btn's */
  text-shadow: none !important;
  background-image: none !important;
  background-repeat: initial !important;
  box-shadow: none;
  transition: all 0.375s ease;
  /* Default .btn colors */
  background-color: #777;
  border-color: #777;
  color: #fff; }
  .btn:hover, .btn:focus {
    /* Default .btn colors */
    background-color: #464646;
    border-color: #464646;
    color: #fff; }
  .btn.btn-success {
    background-color: #81B548;
    border-color: #81B548; }
    .btn.btn-success:hover, .btn.btn-success:focus {
      background-color: #4e9d38;
      border-color: #4e9d38; }
  .btn:btn-primary {
    background-color: #164f84;
    border-color: #164f84; }
    .btn:btn-primary:hover, .btn:btn-primary:focus {
      background-color: #003065;
      border-color: #003065; }
  .btn:btn-danger {
    background-color: #DB3949;
    border-color: #DB3949; }
    .btn:btn-danger:hover, .btn:btn-danger:focus {
      background-color: #bc1a2a;
      border-color: #bc1a2a; }

.rbt-menu > .dropdown-item {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block; }
