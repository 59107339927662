.MemberHome {
  padding: 0 0;
  text-align: justify;
  height: 100%;
  font-size: 9pt;
}

.MemberHome div {
  padding-top: 5px;
}

.MemberHome h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.MemberHome p {
  color: #000;
}

.MemberHome .row {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
}

.memberSummary .rowOne {
  background-color: white;
  min-height: 200px;
}

.memberSummary .rowTwo {
  background-color: white;
  min-height: 330px;
}

.memberPreferences .rowOne {
  background-color: white;
  min-height: 190px;
}

.memberPreferences .rowTwo {
  background-color: white;
  min-height: 390px;
}

.memberPreferences .formSpace {
  padding-left: 20px;
}

.memberNotifications .rowOne {
  background-color: white;
  min-height: 500px;
}

.memberNotifications .formSpace {
  padding-left: 20px;
}

.material-switch > input[type="checkbox"] {
  display: none;   
}

.material-switch > label {
  cursor: pointer;
  height: 0px;
  position: relative; 
  width: 40px;  
}

.material-switch > label::before {
  background: rgb(0, 0, 0);
  box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  content: '';
  height: 16px;
  margin-top: -8px;
  position:absolute;
  opacity: 0.3;
  transition: all 0.4s ease-in-out;
  width: 40px;
}
.material-switch > label::after {
  background: rgb(255, 255, 255);
  border-radius: 16px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
  content: '';
  height: 24px;
  left: -4px;
  margin-top: -8px;
  position: absolute;
  top: -4px;
  transition: all 0.3s ease-in-out;
  width: 24px;
}
.material-switch > input[type="checkbox"]:checked + label::before {
  background: inherit;
  opacity: 0.5;
}
.material-switch > input[type="checkbox"]:checked + label::after {
  background: inherit;
  left: 20px;
}