.MedicationRequestModal ul {
  list-style: none;
  padding-left: 0; }

.MedicationRequestModal .radio--group--button label {
  margin: 5px; }
  .MedicationRequestModal .radio--group--button label input {
    margin-right: 10px; }

.PhoneInputCountryIcon--border {
  background-color: white !important;
  box-shadow: 0 0 0 var(--PhoneInputCountryFlag-borderWidth) white, inset 0 0 0 var(--PhoneInputCountryFlag-borderWidth) white !important; }
