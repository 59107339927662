/**
 * 1. Avoid the IE 10-11 `min-height` bug.
 * 2. Set `flex-shrink` to `0` to prevent some browsers from
 *    letting these items shrink to smaller than their content's default
 *    minimum size. See http://bit.ly/1Mn35US for details.
 * 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
 */
.Site {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* 1, 3 */ }

.Site-header,
.Site-footer {
  flex: none;
  /* 2 */ }

.Site-content {
  display: flex;
  flex-direction: column;
  height: 100vh;
  flex: 1 0 auto;
  /* 2 */
  padding: var(--space) var(--space) 0;
  width: 100%; }
  .Site-content .page-title {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    text-align: left; }
  .Site-content .container {
    width: 100% !important; }
  .Site-content:after {
    content: '\00a0';
    /* &nbsp; */
    display: block;
    margin-top: var(--space);
    height: 0px;
    visibility: hidden; }
  .Site-content--full {
    padding: 0; }
    .Site-content--full:after {
      content: none; }

.main {
  flex: 1 0 auto; }

.modal-dialog {
  margin: 100px auto; }

.Site .navbar-brand {
  font-weight: bold; }

.mobile-Guard {
  text-align: center; }
  .mobile-Guard .row {
    padding-top: 20px; }
  .mobile-Guard .page-title {
    padding-top: 100px; }

@media (--break-lg) {
  .Site-content {
    padding-top: var(--space-lg); }
  .Site-content::after {
    margin-top: var(--space-lg); } }

@media (min-width: 1000px) {
  .mobile-Guard {
    display: none; } }

.col--nopadding {
  padding: 0; }

.pad-right {
  padding-right: 15px; }
