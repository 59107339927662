/* Brand colors */
/* Supporting colors */
.AboutUs .aboutUs {
  padding: 0 0;
  text-align: left;
  height: 100%; }
  .AboutUs .aboutUs div {
    padding-top: 5px; }
  .AboutUs .aboutUs p {
    color: #000; }
