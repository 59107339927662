.RequestDetail .requestDetail {
  padding: 0 0;
  text-align: center;
  height: 100%;
}

.RequestDetail .requestDetail div {
  padding-top: 5px;
}

.RequestDetail .requestDetail h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
}

.RequestDetail .requestDetail p {
  color: #000;
}