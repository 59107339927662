.DonateMoney .donateMoney {
  padding: 0 0;
  text-align: center;
  height: 100%; }

.DonateMoney div {
  padding-top: 5px; }

.DonateMoney h1 {
  font-family: "Open Sans", sans-serif;
  font-weight: 600; }

.DonateMoney p {
  color: #000; }
